import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStyles } from './style';
import Fuse from 'fuse.js';
import CancelIcon from '@material-ui/icons/Cancel';
import { useGetIngredients, useUpdateIngredients } from 'graphql/hooks/dishes.hooks';
import ItemList from './ItemList';
import { useSnackbar } from 'notistack';
import { IIngredientItem } from './types';

const DishOptions = () => {
  const classes = useStyles();

  const { data } = useGetIngredients();

  const snackbar = useSnackbar();
  const { updateIngredientsInfo } = useUpdateIngredients();
  const [initialValues, setInitialValues] = useState<IIngredientItem[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (data && data.getIngredientsInfo) {
      console.log(' data.getIngredientsInfo', data.getIngredientsInfo);
      setInitialValues(data.getIngredientsInfo);
    }
  }, [data]);

  const onSearchChange = (q: string) => {
    if (q.trim() === '') {
      setInitialValues(data.getIngredientsInfo);
    } else {
      const fuse = new Fuse(data.getIngredientsInfo, {
        keys: ['title'],
        isCaseSensitive: false,
        shouldSort: true,
        minMatchCharLength: 3
      });
      setInitialValues(fuse.search(q).map((res: any) => res.item));
    }
    setSearchQuery(q);
  };

  const updateIngredients = (itemKey: string, action: boolean) => {
    let updateList = [...initialValues];
    updateList.map((singleItem: IIngredientItem) => {
      if (singleItem.key === itemKey) {
        singleItem.disabled = action;
      }
    });
    setInitialValues(updateList);
  };

  const saveData = async () => {
    let ingredientItems: Array<IIngredientItem> = [];
    initialValues.forEach((item: IIngredientItem, index: number) => {
      let tempItem = {
        title: item.title,
        disabled: item.disabled
      };
      ingredientItems.push(tempItem);
    });
    const response = await updateIngredientsInfo(ingredientItems);

    if (response && response.data && response.data.updateIngredientsInfo) {
      snackbar.enqueueSnackbar('Ingredients updated succesfully', {
        variant: 'success'
      });
    } else {
      snackbar.enqueueSnackbar('Ingredients update failed!', {
        variant: 'error'
      });
    }
  };
  return (
    <Box flex={1}>
      <Box flex={1} justifyContent="center" width={'100%'} paddingY={2} display="flex">
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <TextField
              InputProps={{
                endAdornment:
                  searchQuery !== '' ? (
                    <CancelIcon
                      color="primary"
                      className={classes.cancelIcon}
                      onClick={() => {
                        onSearchChange('');
                      }}
                    />
                  ) : null
              }}
              className={classes.searchBox}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
              value={searchQuery}
              variant="outlined"
              placeholder="Enter name to find Dish options"
              name="searchquery"
              size="small"
            />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Box>
      <Box display="flex" justifyContent={'flex-end'} paddingLeft={2}>
        <Button variant="contained" color="secondary" onClick={saveData}>
          SAVE CHANGES
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <Box display="flex" paddingY={2} justifyContent={'center'}>
            <Typography variant="h5">Active Options</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" paddingY={2} justifyContent={'center'}>
            <Typography variant="h5">Disabled Options</Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} className={classes.scroll1}>
          <Box padding={2}>{<ItemList initialValues={initialValues} updateIngredients={updateIngredients} isDisabled={false} />}</Box>
        </Grid>
        <Grid item xs={6} className={classes.scroll2}>
          <Box padding={2}>{<ItemList initialValues={initialValues} updateIngredients={updateIngredients} isDisabled={true} />}</Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DishOptions;
