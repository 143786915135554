import { gql } from 'apollo-boost';

export default gql`
  query getRestaurantsByZipcode($input: GetRestaurantsByZipcodeInput!) {
    getRestaurantsByZipcode(input: $input) {
      edges {
        node {
          id
          name
          logo {
            id
            url
          }
        }
      }
    }
  }
`;
