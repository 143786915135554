import { gql } from 'apollo-boost';

export default gql`
  query getSellerOngoingOrders($input: GetSellerOngoingOrdersInput!) {
    getSellerOngoingOrders(input: $input) {
      id
      status
      deliveryStatusBoons
      deliveryProvider
      deliveryMode
      cookingTimeMins
      noOfBags
      specialHandlingInstruction
      dropOffInstruction
      deliveryNote
      expectTime
      orderNumber
      createdAt
      updatedAt
      bizExpectTime
      deliveryDistance
      timeLines {
        msg
        status
      }
      buyer {
        uid
        phoneNumber
        email
        displayName
      }
      orderEmail
      orderPhoneNumber
      paymentType
      type
      total {
        intValue
        shift
      }
      tableName
      partySize
      items {
        dishTitle
        count
      }
    }
  }
`;
