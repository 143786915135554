import React, { useState, useContext } from 'react';
import { Box, Typography, TextField, Button, Grid } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import { useGetRestaurantByNanoId } from 'graphql/hooks/restaurant.hooks';
// import { map } from 'lodash';
// import { useStyles } from './style';
import { AuthContext } from 'fbase/authContext';
import { Redirect } from 'react-router-dom';
// import { useGetGroceryStoreByNanoId } from 'graphql/hooks/groceryStore.hooks';
import RenderItem from './RenderItem';

const RestaurantList = () => {
  // const classes = useStyles();

  const [searchString, setSearchString] = useState('');
  // const [selectedOps, setSelectedOps] = useState('');

  // const handleChange = (event: any) => {
  //   setSelectedOps(event.target.value);
  // };
  const { queryRestaurant, data, loading } = useGetRestaurantByNanoId();

  // const { queryGroceryStore, data: groceryStoreData, loading: loadingGroceryStore } = useGetGroceryStoreByNanoId();

  const { currentUser } = useContext(AuthContext);

  const onSearch = () => {
    if (searchString.trim() !== '') {
      queryRestaurant(searchString);
      // if (selectedOps === 'restaurant') {
      //   queryRestaurant(searchString);
      // }
      // if (selectedOps === 'groceryStore') {
      //   queryGroceryStore(searchString);
      // }
    }
  };

  const renderRestaurant = () => {
    if (data && data.getRestaurantByNanoid) {
      const { id, name, logo } = data.getRestaurantByNanoid;

      const { url: imageUrl } = logo || { url: undefined };

      const restaurantName = name || 'Unnamed Restaurant';
      return <RenderItem id={id} imageUrl={imageUrl} isRestaurant={true} itemName={restaurantName} />;
    }
    return null;
  };

  // const renderGroceryStore = () => {
  //   if (groceryStoreData && groceryStoreData.getGroceryStoreByNanoid) {
  //     const { id, name, logo } = groceryStoreData.getGroceryStoreByNanoid;
  //     const { url: imageUrl } = logo || { url: undefined };
  //     return <RenderItem id={id} imageUrl={imageUrl} isRestaurant={false} itemName={name} />;
  //   }
  //   return null;
  // };

  if (currentUser) {
    const id = localStorage.getItem('ops_rest_id');
    // const store_id = localStorage.getItem('ops_store_id');
    if (id) {
      return <Redirect to={'/' + id + '/home'} />;
    }
    // if (store_id) {
    //   return <Redirect to={'/grocerystore/' + store_id + '/home'} />;
    // }
  }

  return (
    <Box padding={2}>
      <Box marginBottom={2}>
        <Typography variant="body1">Enter restaurant ID to find your restaurant. (You can view restaurant ID in your restaurant's home page in biz app)</Typography>
        <br />
        <TextField type="text" placeholder="Enter restaurant ID" variant="outlined" value={searchString} onChange={(e: any) => setSearchString(e.target.value)} />
        <br />

        {/* <FormControl component="fieldset">
          <RadioGroup aria-label="selector" name="selector" value={selectedOps} onChange={handleChange}>
            <FormControlLabel value="restaurant" control={<Radio />} label="Restaurant" />
            <FormControlLabel value="groceryStore" control={<Radio />} label="Grocery Store" />
          </RadioGroup>
        </FormControl> */}
      </Box>

      <Button variant="contained" color="secondary" size="small" onClick={onSearch} disabled={searchString === ''} endIcon={<SearchIcon fontSize="small" />}>
        Search
      </Button>

      {!loading && data && !data.getRestaurantByNanoid && <p>No restaurants available for given restaurant ID.</p>}
      {/* {!loadingGroceryStore && groceryStoreData && !groceryStoreData.getGroceryStoreByNanoid && <p>No grocery stores are available for given store ID.</p>} */}
      {/* <Box paddingY={2}>{selectedOps === 'restaurant' ? <Grid container={true}>{renderRestaurant()}</Grid> : <Grid container={true}>{renderGroceryStore()}</Grid>}</Box> */}
      <Box paddingY={2}>
        <Grid container={true}>{renderRestaurant()}</Grid>
      </Box>
    </Box>
  );
};

export default RestaurantList;
