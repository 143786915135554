import { gql } from 'apollo-boost';

export default gql`
  query getOrderByOrderId($input: GetOrderByOrderIdInput!) {
    getOrderByOrderId(input: $input) {
      id
      status
      doordashClassicQuoteId
      deliveryStatusBoons
      expectTime
      orderNumber
      note
      deliveryNote
      redeemPoints
      adjustReason
      isDiscounted
      deliveryProvider
      deliveryMode
      cookingTimeMins
      bizExpectTime
      noOfBags
      specialHandlingInstruction
      dropOffInstruction
      deliveryAddress {
        text
      }
      timeLines {
        msg
        status
      }
      deliveryTimelineBoons {
        msg
        status
      }
      deliveryDistance
      buyer {
        phoneNumber
        email
        displayName
      }
      seller {
        name
        address {
          text
        }
        phone
      }
      orderEmail
      orderPhoneNumber
      paymentType
      type
      createdAt
      tableName
      partySize
      subTotal {
        intValue
        shift
      }
      taxRate {
        intValue
        shift
      }
      total {
        intValue
        shift
      }
      tip {
        intValue
        shift
      }
      tax {
        intValue
        shift
      }
      deliveryFee {
        intValue
        shift
      }
      couponDiscount {
        intValue
        shift
      }
      lokobeeFee {
        intValue
        shift
      }
      adjustAmount {
        intValue
        shift
      }
      totalAfterAdjust {
        intValue
        shift
      }
      coupon {
        code
      }
      items {
        dishId
        note
        count
        dishType
        isAlcohol
        isReward
        isDiscounted
        points
        dishCategoryTitle
        dishTitle
        dishDescription

        dishPrice {
          price {
            intValue
            shift
          }
          size
          discountAmount
        }
        dishExtras {
          count
          dishExtraGroupId
          dishExtraGroupTitle
          dishExtraItem {
            title
            price {
              intValue
              shift
            }
          }
        }

        pizzaBasePrice {
          id
          size {
            title
          }
          price {
            intValue
            shift
          }
          discountAmount
        }
        pizzaCrust {
          crustId
          title
          price {
            intValue
            shift
          }
        }
        pizzaSauces {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }

        pizzaCheeses {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }

        pizzaToppings {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }
      }
    }
  }
`;
