import { gql } from 'apollo-boost';

export default gql`
  query getSellerOrders($input: GetSellerOrdersInput!) {
    getSellerOrders(input: $input) {
      edges {
        node {
          id
          status
          expectTime
          bizExpectTime
          deliveryProvider
          orderNumber
          createdAt
          updatedAt
          deliveryDistance
          timeLines {
            msg
            status
          }
          buyer {
            uid
            phoneNumber
            email
            displayName
          }
          orderEmail
          orderPhoneNumber
          paymentType
          type
          total {
            intValue
            shift
          }
          tableName
          partySize
          items {
            dishTitle
            count
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
