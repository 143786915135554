import Big from 'big.js';
import { formatPhoneNumber, convertPrice } from 'util/number';
import { groupBy, map } from 'lodash';
import grey from '@material-ui/core/colors/grey';
import moment from 'moment';

// Add dish extras price to dish price for display purpose
export const parseOrderItems = (items: any) => {
  if (items && items.length) {
    return items.map((item: any) => {
      const { dishPrice } = item;
      const dishType = item.dishType || 'REGULAR';
      if (dishType === 'REGULAR') {
        let finalPrice = Big(0);
        const { dishExtras, ...rest } = item;
        const {
          price: { intValue, shift }
        } = dishPrice || { price: { intValue: 0, shift: 0 } };

        finalPrice = Big(convertPrice(intValue, shift));

        if (dishExtras && dishExtras.length) {
          let sum = Big(0);

          for (const extras of dishExtras) {
            const {
              dishExtraItem: {
                price: { intValue: _intValue, shift: _shift }
              }
            } = extras;
            const price = convertPrice(_intValue, _shift);

            sum = sum.plus(Big(price));
          }

          finalPrice = finalPrice.plus(sum);

          const _extras = map(groupBy(dishExtras, 'dishExtraGroupId'), (value) => {
            if (value && value.length) {
              const { dishExtraGroupTitle } = value[0];
              const groupTitle = dishExtraGroupTitle || '';
              const items = map(value, ({ dishExtraItem: { title } }) => {
                const extrasTitle = title || '';
                return extrasTitle;
              });

              return {
                groupTitle,
                items
              };
            } else return value;
          });

          return { ...rest, dishPrice: finalPrice.toFixed(2), dishExtras: _extras, dishOriginalPrice: dishPrice };
        }
        return { ...rest, dishPrice: finalPrice.toFixed(2), dishOriginalPrice: dishPrice };
      }

      if (dishType === 'PIZZA') {
        let finalPrice = Big(0.0);
        const { pizzaBasePrice, pizzaToppings, pizzaCheeses, pizzaSauces, pizzaCrust } = item;

        const {
          price: { intValue, shift }
        } = pizzaBasePrice || { price: { intValue: 0, shift: 0 } };

        finalPrice = finalPrice.plus(Big(convertPrice(intValue, shift)));

        if (pizzaCrust && pizzaCrust.price) {
          const {
            price: { intValue: crustPriceVal, shift: crustPriceShift }
          } = pizzaCrust || { price: { intValue: 0, shift: 0 } };

          finalPrice = finalPrice.plus(Big(convertPrice(crustPriceVal, crustPriceShift)));
        }

        const leftToppings: any = {
          exclusions: [],
          additions: []
        };
        const leftSauces: any = {
          exclusions: [],
          additions: []
        };
        const leftCheeses: any = {
          exclusions: [],
          additions: []
        };

        const rightToppings: any = {
          exclusions: [],
          additions: []
        };
        const rightSauces: any = {
          exclusions: [],
          additions: []
        };
        const rightCheeses: any = {
          exclusions: [],
          additions: []
        };

        let halfAndHalf = false;

        if (pizzaToppings && pizzaToppings.length) {
          pizzaToppings.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };
            finalPrice = finalPrice.plus(Big(convertPrice(_intValue, _shift)));
            const toppingsText = `${toppingTitle} ( ${toppingDensity} )`;
            const excludeText = `No ${toppingTitle}`;

            if (preset) {
              if (side === 'Left') {
                halfAndHalf = true;
                if (toppingDensity !== 'Regular') {
                  leftToppings.additions.push(toppingsText);
                }
                rightToppings.exclusions.push(excludeText);
              }
              if (side === 'Right') {
                halfAndHalf = true;
                if (toppingDensity !== 'Regular') {
                  rightToppings.additions.push(toppingsText);
                }
                leftToppings.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftToppings.exclusions.push(excludeText);
                rightToppings.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                leftToppings.additions.push(toppingsText);
                rightToppings.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftToppings.additions.push(toppingsText);
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightToppings.additions.push(toppingsText);
              }
              if (side === 'Whole') {
                leftToppings.additions.push(toppingsText);
                rightToppings.additions.push(toppingsText);
              }
            }
          });
        }

        if (pizzaSauces && pizzaSauces.length) {
          pizzaSauces.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };
            finalPrice = finalPrice.plus(Big(convertPrice(_intValue, _shift)));
            const toppingsText = `${toppingTitle} ( ${toppingDensity} )`;
            const excludeText = `No ${toppingTitle}`;

            if (preset) {
              if (side === 'Left') {
                halfAndHalf = true;
                if (toppingDensity !== 'Regular') {
                  leftSauces.additions.push(toppingsText);
                }
                rightSauces.exclusions.push(excludeText);
              }
              if (side === 'Right') {
                halfAndHalf = true;
                if (toppingDensity !== 'Regular') {
                  rightSauces.additions.push(toppingsText);
                }
                leftSauces.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftSauces.exclusions.push(excludeText);
                rightSauces.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                rightSauces.additions.push(toppingsText);
                leftSauces.additions.push(toppingsText);
              }
            } else {
              const toppingsText = `${toppingTitle} ( ${toppingDensity} )`;
              if (side === 'Left') {
                halfAndHalf = true;
                leftSauces.additions.push(toppingsText);
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightSauces.additions.push(toppingsText);
              }
              if (side === 'Whole') {
                leftSauces.additions.push(toppingsText);
                rightSauces.additions.push(toppingsText);
              }
            }
          });
        }

        if (pizzaCheeses && pizzaCheeses.length) {
          pizzaCheeses.forEach(({ side, preset, toppingDensity, toppingTitle, toppingPrice }: any) => {
            const { intValue: _intValue, shift: _shift } = toppingPrice || { intValue: 0, shift: 0 };
            finalPrice = finalPrice.plus(Big(convertPrice(_intValue, _shift)));
            const toppingsText = `${toppingTitle} ( ${toppingDensity} )`;
            const excludeText = `No ${toppingTitle}`;
            if (preset) {
              if (side === 'Left') {
                halfAndHalf = true;
                if (toppingDensity !== 'Regular') {
                  leftCheeses.additions.push(toppingsText);
                }
                rightCheeses.exclusions.push(excludeText);
              }
              if (side === 'Right') {
                halfAndHalf = true;
                if (toppingDensity !== 'Regular') {
                  rightCheeses.additions.push(toppingsText);
                }
                leftCheeses.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity === 'None') {
                leftCheeses.exclusions.push(excludeText);
                rightCheeses.exclusions.push(excludeText);
              }
              if (side === 'Whole' && toppingDensity !== 'Regular' && toppingDensity !== 'None') {
                rightCheeses.additions.push(toppingsText);
                leftCheeses.additions.push(toppingsText);
              }
            } else {
              if (side === 'Left') {
                halfAndHalf = true;
                leftCheeses.additions.push(toppingsText);
              }
              if (side === 'Right') {
                halfAndHalf = true;
                rightCheeses.additions.push(toppingsText);
              }
              if (side === 'Whole') {
                leftCheeses.additions.push(toppingsText);
                rightCheeses.additions.push(toppingsText);
              }
            }
          });
        }

        return {
          ...item,
          dishPrice: finalPrice.toFixed(2),
          toppings: { leftToppings, rightToppings },
          sauces: { leftSauces, rightSauces },
          cheeses: { leftCheeses, rightCheeses },
          halfAndHalf,
          dishOriginalPrice: pizzaBasePrice
        };
      }

      return { ...item, dishPrice: 0, dishOriginalPrice: dishPrice };
    });
  }
  return items;
};

export const formatPriceToString = (price: any) => {
  if (!price) {
    return null;
  }

  if (price instanceof Big) {
    return price.toFixed(2).toString();
  }

  return Big(price).toFixed(2);
};

export const printOrderDetails = (data: any, items: any[]) => {
  const {
    orderNumber,
    expectTime,
    buyer: { displayName, phoneNumber },
    // subTotal,
    // tip,
    // total,
    // tax,
    paymentType,
    type,
    tableName,
    createdAt,
    seller,
    note: orderNote,
    deliveryAddress,
    deliveryNote,
    deliveryDistance
  } = data;

  const { name, address, phone } = seller;
  const restaurantName = name || '';
  const { text: restaurantAddress } = address || { text: '' };
  const restaurantPhone = formatPhoneNumber(phone) ? formatPhoneNumber(phone) : phone;
  const customerPhone = formatPhoneNumber(phoneNumber) ? formatPhoneNumber(phoneNumber) : phoneNumber;

  const expectTimeMoment = moment(expectTime);
  const createdAtMoment = moment(createdAt);

  let orderType = '';
  if (type === 'DINING') orderType = `Dining - ${tableName}`;
  if (type === 'TAKEOUT') orderType = `Take out`;

  const { text: _deliveryAddress } = deliveryAddress || { text: '' };

  const content = `
      <html>
      <head>
      <meta name="format-detection" content="telephone=no">
      <link href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap" rel="stylesheet">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
      <style type="text/css">
        @page 
        {
          margin: 0mm; 
        }
        *{ 
          color-adjust: exact;
          -webkit-print-color-adjust: exact;
          print-color-adjust: exact; 
        }
        html{
          margin:0px;
          width:100%;
          display:flex;
          justify-content:center;
          font-family: 'Ubuntu', sans-serif;
        }
        body{
          margin:2px;
          margin-top:20px;
          padding:0;
          width:278.4px
        }
        h1,h2,h3,h4,h5,h6,p{
          margin:0
        }
        .flexbox{
          display:flex;
          justify-content:space-between;
          align-items:center
        }
        .customerDetails{
          border:solid 2px ${grey[300]};
          padding:5px;
          font-size:10px;
        }
        .customerDetailsHeader{
          font-size:12px;
          align-items: flex-start;
          padding:1px;
        }
        .noWrap{
          white-space: nowrap;
        }
        .boldText{
          font-weight:bold
        }
        .line{
          background-color:${grey[300]};
          height:2px;
          width:100%;
          border:none
        }
        .dishDetails p{
          font-size:15px;
        }
      </style>
      </head>
      <body>
      <div style="width:100%">
        <div class="flexbox">
          <h2>${orderNumber.substr(orderNumber.length - 4)}</h2>
          <h2>${
            type === 'TAKEOUT'
              ? expectTimeMoment.format('ddd').toUpperCase()
              : moment()
                  .format('ddd')
                  .toUpperCase()
          }  </h2>
        </div>      
        <hr class="line" />
        <div class="flexbox" style="align-items: flex-start;">
          <p style="font-size:12px;">${restaurantName}</p>
          <h4 class="noWrap">${orderType.toUpperCase()}</h4>
        </div>
        
        <div class="flexbox" style="padding:2px">
          <div style="font-size:8px;">
            <p>${restaurantAddress}</p>
            <p >${restaurantPhone}</p>
          </div>
          <div style="text-align:right">
            <h5 class="noWrap">        
              ${type === 'TAKEOUT' ? expectTimeMoment.format('MMM-DD').toUpperCase() : ''}          
            </h5>
            <h5>
              ${type === 'TAKEOUT' ? expectTimeMoment.format('HH:mm A') : ''}  
            </h5>
          </div>
        </div>
        <div class="customerDetails" >
          <div class="flexbox boldText customerDetailsHeader">
            <p>${displayName}</p>
            <p class="noWrap">${paymentType === 'ONLINE_STRIPE' ? 'PAID' : 'PAYMENT DUE'}</p>
          </div>
          <p style="padding:1px">${customerPhone}</p>
          <div class="flexbox" style="align-items: flex-start;padding:1px;">
            <p class="noWrap">Order#: ${orderNumber}</p>
            <p>${createdAtMoment.format('MMM-DD-YYYY HH:mm A')}</p>
          </div>
          <br />
          ${
            type === 'DELIVERY'
              ? `
          <div>
            <p><span class="boldText">Delivery Address: </span> ${_deliveryAddress}</p>
            ${deliveryNote ? `<p><span class="boldText">Delivery Note: </span> ${deliveryNote}</p>` : ``}
            ${deliveryDistance !== 0 ? `<p><span class="boldText">Estimated Distance: </span> ${deliveryDistance.toFixed(2)} miles</p>` : ``}
          </div>
          `
              : ``
          }
        </div>
        <div class="dishDetails">
          <hr class="line" />
          <p style="font-size:14px;padding:0 5px;"> ITEMS (${items.length ? items.length : ''})</p>
          <hr class="line" />
          ${items
            .map((item: any) => {
              const { dishTitle, count, dishPrice, dishExtras, note, dishOriginalPrice } = item;
              const dishType = item.dishType || 'REGULAR';

              if (dishType === 'REGULAR') {
                const _title = dishTitle || '';

                const _dishSize = dishOriginalPrice.size || '';
                const extras = map(dishExtras, (extra) => {
                  const { groupTitle, items } = extra;
                  return `${groupTitle} : ${items.join(', ')}`;
                }).join('<br />');

                return `
                  <h5>${count}x ${_title} </h5>
                  <p>$ ${formatPriceToString(dishPrice * count)}</p>
                  <p>Dish size: ${_dishSize}</p>
                  ${extras ? `<p>${extras}</p>` : ``}
                  ${note ? `<p style="font-weight:bold">Dish Note:</p><p>${note}</p>` : ``}
                  <hr class="line" />
                `;
              }

              if (dishType === 'PIZZA') {
                const {
                  count,
                  dishTitle,
                  pizzaCrust,
                  toppings: { leftToppings, rightToppings },
                  sauces: { leftSauces, rightSauces },
                  cheeses: { leftCheeses, rightCheeses },
                  halfAndHalf,
                  pizzaBasePrice,
                  dishPrice,
                  note
                } = item;

                const _title = dishTitle || '';
                const { title: _dishSize } = pizzaBasePrice && pizzaBasePrice.size ? pizzaBasePrice.size : { title: '' };

                const { title: crust } = pizzaCrust || { title: '' };

                let toppingsDisplay = ``;

                if (halfAndHalf) {
                  toppingsDisplay += `
                    <p>${'LEFT HALF'}</p>
                    <p>${_title}</p>
                  `;
                  // Left Exclusions
                  if (!!leftSauces.exclusions && !!leftSauces.exclusions.length) {
                    toppingsDisplay += map(leftSauces.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }

                  if (!!leftCheeses.exclusions && !!leftCheeses.exclusions.length) {
                    toppingsDisplay += map(leftCheeses.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftToppings.exclusions && !!leftToppings.exclusions.length) {
                    toppingsDisplay += map(leftToppings.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  // Left Additions
                  if (!!leftSauces.additions && !!leftSauces.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftCheeses.additions && !!leftCheeses.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftToppings.additions && !!leftToppings.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }

                  toppingsDisplay += `
                    <p>${'RIGHT HALF'}</p>
                    <p>${_title}</p>
                  `;

                  // Right Exclusions
                  if (!!rightSauces.exclusions && !!rightSauces.exclusions.length) {
                    toppingsDisplay += map(rightSauces.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }

                  if (!!rightCheeses.exclusions && !!rightCheeses.exclusions.length) {
                    toppingsDisplay += map(rightCheeses.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!rightToppings.exclusions && !!rightToppings.exclusions.length) {
                    toppingsDisplay += map(rightToppings.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  // right Additions
                  if (!!rightSauces.additions && !!rightSauces.additions.length) {
                    toppingsDisplay += map(rightToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!rightCheeses.additions && !!rightCheeses.additions.length) {
                    toppingsDisplay += map(rightToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!rightToppings.additions && !!rightToppings.additions.length) {
                    toppingsDisplay += map(rightToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                } else {
                  toppingsDisplay += `<p>${_title}</p>`;

                  if (!!leftSauces.exclusions && !!leftSauces.exclusions.length) {
                    toppingsDisplay += map(leftSauces.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }

                  if (!!leftCheeses.exclusions && !!leftCheeses.exclusions.length) {
                    toppingsDisplay += map(leftCheeses.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftToppings.exclusions && !!leftToppings.exclusions.length) {
                    toppingsDisplay += map(leftToppings.exclusions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">
                            <i class="material-icons" style="color:#f44336">cancel</i>
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  // Left Additions
                  if (!!leftSauces.additions && !!leftSauces.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftCheeses.additions && !!leftCheeses.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                  if (!!leftToppings.additions && !!leftToppings.additions.length) {
                    toppingsDisplay += map(leftToppings.additions, (topping) => {
                      return `
                          <div style="display:flex;align-items:center;padding-Left:16px">                           
                            <p>${topping}</p>
                          </div>
                      `;
                    }).join('');
                  }
                }

                return `
                  <h5>${count}x Pizza</h5>
                  <p>$ ${formatPriceToString(dishPrice * count)}</p>
                  <div>
                    <p>${_dishSize}</p>
                    <p>${crust}</p>
                    
                    ${toppingsDisplay}
                    
                    ${note ? `<p style="font-weight:bold">Dish Note:</p><p>${note}</p>` : ``}
                  </div>
                  <hr class="line" />
                `;
              }

              return ``;
            })
            .join('')}

            ${
              orderNote
                ? `            
            <p style="font-weight:bold">Order Note:</p>
            <p>${orderNote}</p>
            <hr class="line" />
            `
                : ``
            }
            
            
        </div>
        <div class="flexbox" style="justify-content:center;font-size:8px;">
            <p>Powered by <span class="boldText">Lokobee</span></p>
        </div>
      </div>
      </body>
      </html>
      `;

  // Bill info for now not displayed in print
  // Maybe needed in future
  //
  //<div class="flexbox">
  //         <p>Subtotal <span class="boldText">(${items.length} ITEMS)</span></p>
  //         <p class="boldText">$${convertPrice(subTotal.intValue, subTotal.shift)}</p>
  //       </div>
  //       <div class="flexbox">
  //         <p>Tax</p>
  //         <p class="boldText">$${convertPrice(tax.intValue, tax.shift)}</p>
  //       </div>
  //       <div class="flexbox">
  //         <p>Tip</p>
  //         <p class="boldText">$${convertPrice(tip.intValue, tip.shift)}</p>
  //       </div>
  //       <div class="flexbox">
  //         <p>Total Amount</p>
  //         <p class="boldText">$${convertPrice(total.intValue, total.shift)}</p>
  //       </div>
  //       <hr class="line" />

  const hiddFrame = document.createElement('iframe');
  hiddFrame.style.position = 'fixed';
  hiddFrame.style.visibility = 'hidden';

  hiddFrame.srcdoc = content;
  document.body.appendChild(hiddFrame);
  hiddFrame.onload = () => {
    hiddFrame.contentWindow?.print();
  };
};
