import React, { useState } from 'react';
import { IOrder } from 'types';
import { useStyles } from './style';
import { Box, Grid, Tabs, Tab, withStyles, useTheme, Typography } from '@material-ui/core';
import { map } from 'lodash';
import { TableServiceOrderCard } from 'components/OrderCard';

interface IProps {
  tableServiceNewOrders: IOrder[] | null;
  tableServiceClosedOrders: IOrder[] | null;
}

const TableService = ({ tableServiceNewOrders, tableServiceClosedOrders }: IProps) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);

  const theme = useTheme();

  const StyledTabs = withStyles({
    indicator: {
      backgroundColor: theme.palette.primary.main,
      height: '3px'
    }
  })((props: any) => <Tabs {...props} />);

  if ((tableServiceNewOrders && tableServiceNewOrders.length) || (tableServiceClosedOrders && tableServiceClosedOrders.length)) {
    return (
      <Box width="100%">
        <Box paddingX={2}>
          <StyledTabs value={activeTab} onChange={(_e: any, index: any) => setActiveTab(index)}>
            <Tab label="New" value={0} />
            <Tab label="Closed" value={1} />
          </StyledTabs>
        </Box>

        <Grid container={true} className={classes.container}>
          {activeTab === 0 &&
            tableServiceNewOrders &&
            tableServiceNewOrders.length > 0 &&
            map(tableServiceNewOrders, ({ id, items, createdAt, updatedAt, status, buyer: { displayName }, tableName = '' }) => {
              const _items = map(items, ({ dishTitle, count }) => {
                return {
                  title: dishTitle || '',
                  count
                };
              });
              return (
                <Grid item={true} xs={12} sm={6} lg={4} className={classes.item} key={id}>
                  <TableServiceOrderCard items={_items} id={id} createdAt={createdAt} status={status} name={displayName} tableName={tableName} updatedAt={updatedAt} />
                </Grid>
              );
            })}

          {activeTab === 0 && tableServiceNewOrders && tableServiceNewOrders.length === 0 && <Typography variant="h6">No new table service orders.</Typography>}

          {activeTab === 1 &&
            tableServiceClosedOrders &&
            tableServiceClosedOrders.length > 0 &&
            map(tableServiceClosedOrders, ({ id, items, createdAt, updatedAt, status, buyer: { displayName }, tableName = '' }) => {
              const _items = map(items, ({ dishTitle, count }) => {
                return {
                  title: dishTitle || '',
                  count
                };
              });
              return (
                <Grid item={true} xs={12} sm={6} lg={4} className={classes.item} key={id}>
                  <TableServiceOrderCard items={_items} id={id} createdAt={createdAt} status={status} name={displayName} tableName={tableName} updatedAt={updatedAt} />
                </Grid>
              );
            })}

          {activeTab === 1 && tableServiceClosedOrders && tableServiceClosedOrders.length === 0 && <Typography variant="h6">No closed table service orders.</Typography>}
        </Grid>
      </Box>
    );
  }

  return (
    <Box padding={1}>
      <Typography variant="h6">No table service orders found.</Typography>
    </Box>
  );
};

export default TableService;
